import React, { useContext } from "react";
import styled, { css } from "styled-components";
import { graphql, useStaticQuery } from "gatsby";
import { getImage, GatsbyImage, withArtDirection } from "gatsby-plugin-image";
import respond from "../../styles/abstracts/mediaqueries";
import AppContext from "../../context/AppContext";

const StyledKeepingTabsCard = styled.article`
  position: relative;
  padding: 15rem 0;
  margin: 0;
  color: var(--white);
  text-align: center;

  ${respond(
    "phone-port",
    css`
      padding: 0;
      height: 100vh;
    `
  )}

  h3 {
    color: var(--white);
    font-family: var(--body-font);
    font-weight: 200;

    ${respond(
      "phone-port",
      css`
        text-align: center;
        margin-top: 4rem;
      `
    )}
  }

  p {
    ${respond(
      "phone-port",
      css`
        margin-top: auto;
        margin-bottom: 10rem;
      `
    )}
  }

  .content {
    width: 35%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3rem;

    ${respond(
      "tab-port",
      css`
        width: 50%;
      `
    )}
    ${respond(
      "phone-port",
      css`
        width: 100%;
        height: 100%;
        justify-content: flex-start;
        align-items: center;
      `
    )}
  }

  .container {
    ${respond(
      "phone-port",
      css`
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
      `
    )}
  }

  .bg-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }
`;

const KeepingTabsCard = () => {
  const { isPhonePort } = useContext(AppContext);

  const {
    strapiNicoleWebsite: {
      homePage: {
        keepingTabsCard: {
          title,
          text,
          bgImage: { localFile: bgImage, alternativeText: bgAlt },
          mobileImage: { localFile: mobileImage },
          icon: { localFile: icon, alternativeText: iconAlt },
        },
      },
    },
  } = useStaticQuery(query);

  const bigImage = getImage(bgImage);
  const phoneImage = getImage(mobileImage);

  return (
    <StyledKeepingTabsCard>
      <div className="container">
        <div className="content">
          <h3>{title}</h3>
          <img src={icon.publicURL} alt={iconAlt} />
          <p>{text}</p>
        </div>
      </div>
      {isPhonePort ? (
        <GatsbyImage
          image={phoneImage}
          className="bg-image"
          alt={bgAlt}
          objectFit="cover"
          objectPosition="center"
        ></GatsbyImage>
      ) : (
        <GatsbyImage
          image={bigImage}
          className="bg-image"
          alt={bgAlt}
          objectFit="cover"
          objectPosition="center"
        ></GatsbyImage>
      )}
    </StyledKeepingTabsCard>
  );
};

export const query = graphql`
  query KeepingTabsCard {
    strapiNicoleWebsite {
      homePage {
        keepingTabsCard {
          text
          title
          icon {
            alternativeText
            localFile {
              publicURL
            }
          }
          bgImage {
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
            alternativeText
          }
          mobileImage {
            alternativeText
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
          }
        }
      }
    }
  }
`;

export default KeepingTabsCard;
