import React from "react";
import styled, { css } from "styled-components";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import respond from "../styles/abstracts/mediaqueries";

const StyledHeroBottomHeader = styled.div`
  position: absolute;
  z-index: 100;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 13vh;
  background-color: var(--light-black);
  color: var(--white);

  ${respond(
    "tab-land",
    css`
      height: 16vh;
    `
  )}
  ${respond(
    "tab-port",
    css`
      height: 27vh;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    `
  )}
  ${respond(
    "phone-land",
    css`
      height: 54vh;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    `
  )}
  ${respond(
    "phone-port",
    css`
      height: 35vh;
    `
  )}

  .hr {
    border: 1px solid var(--color-secondary);
    height: 0.5rem;
    background-color: var(--color-secondary);
    width: 100%;
    position: absolute;
    top: 0;
  }

  .icons-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;

    ${respond(
      "tab-port",
      css`
        display: grid !important;
        grid-template-columns: 1fr;
        grid-template-rows: repeat(3, max-content);
        align-items: center;
        justify-items: center;
        gap: 2rem;
        height: min-content !important;
      `
    )}
  }

  .icon {
    width: 15rem;

    ${respond(
      "phone-port",
      css`
        width: 12rem;
      `
    )}
  }

  .logos-container {
    display: flex;
    align-items: center;
    gap: 2rem;
    justify-content: space-around;
    width: 100%;

    ${respond(
      "tab-port",
      css`
        grid-row: 2 / 3;
      `
    )}
    ${respond(
      "phone-port",
      css`
        flex-wrap: wrap;
        justify-content: center;
      `
    )}
  }

  .intro,
  .outro {
    min-width: max-content;
  }

  .intro {
    ${respond(
      "tab-port",
      css`
        grid-row: 1 / 2;
      `
    )}
  }

  .outro {
    ${respond(
      "tab-port",
      css`
        grid-row: 3 / 4;
      `
    )}
  }
`;

const HeroBottomHeader = () => {
  const {
    strapiNicoleWebsite: {
      homePage: {
        bottomHero: { intro, outro, featuredLogos: logos },
      },
    },
  } = useStaticQuery(query);

  //console.log(bottomHero);

  return (
    <StyledHeroBottomHeader>
      <hr className="hr" />
      <div className="icons-box container">
        <span className="intro">{intro}</span>
        <div className="logos-container">
          {logos.map(({ localFile, alternativeText, id }) => {
            const image = getImage(localFile);
            return <GatsbyImage image={image} alt={alternativeText} className="icon" key={id} />;
          })}
        </div>
        <span className="outro">{outro}</span>
      </div>
    </StyledHeroBottomHeader>
  );
};

const query = graphql`
  query BottomHero {
    strapiNicoleWebsite {
      homePage {
        bottomHero {
          intro
          outro
          featuredLogos {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED, placeholder: TRACED_SVG)
              }
            }
            alternativeText
            id
          }
        }
      }
    }
  }
`;

export default HeroBottomHeader;
