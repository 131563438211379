import React, { useRef, useEffect, useContext } from "react";
import styled, { css } from "styled-components";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { graphql, useStaticQuery } from "gatsby";
import respond from "../../styles/abstracts/mediaqueries";

import useIsBrowser from "../../hooks/use-is-browser";
import AppContext from "../../context/AppContext";

import Button from "../../components/Button";

const StyledFeaturedCards = styled.section`
  padding-top: 4rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  color: var(--white);
  text-align: center;

  ${respond(
    "tab-port",
    css`
      grid-template-columns: 1fr;
    `
  )}

  .card {
    position: relative;
    padding: 10%;

    &--1 {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
      gap: 2rem;

      ${respond(
        "laptop",
        css`
          box-sizing: content-box;
        `
      )}
    }

    &--2 {
      background-color: var(--light-black);
      border: 20px solid var(--color-secondary);
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: repeat(6, 1fr);
      justify-items: center;
      align-items: center;
      box-sizing: border-box;

      ${respond(
        "phone-port",
        css`
          display: flex;
          flex-direction: column;
          gap: 4rem;
        `
      )}

      .card-logos-container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        gap: 2rem;
        grid-row: 5 / 6;
      }
    }
  }

  .card-title {
    text-transform: uppercase;
    color: var(--white);
  }

  .card-text {
    margin-bottom: 1rem;
  }

  .card-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    z-index: -1;
  }

  .card-logos-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5rem;
    grid-row: 5 / 6;
  }

  .card-2-logo {
    grid-row: 2 / 3;
    filter: brightness(0) invert(1);

    ${respond(
      "phone-port",
      css`
        width: 90%;
      `
    )}
  }

  .card-2-text {
    grid-row: 3 / 4;
    font-size: 2rem;
  }
`;

const FeaturedCards = () => {
  const [cardHeight, setCardHeight] = React.useState("100%");
  const cardTwoRef = useRef(null);
  const isBrowser = useIsBrowser();
  const { isPhonePort } = useContext(AppContext);

  useEffect(() => {
    if (isBrowser) {
      const cardTwoWidth = window.getComputedStyle(cardTwoRef.current).getPropertyValue("width");
      const height = isPhonePort ? "60vh" : cardTwoWidth;
      setCardHeight(height);
    }
  }, [cardHeight, isBrowser, isPhonePort]);

  const {
    strapiNicoleWebsite: {
      homePage: {
        card1: {
          cta,
          link,
          text,
          title,
          image: { alternativeText: alt1, localFile: sharpImage1 },
        },
        card2: {
          text: text2,
          mainLogo: {
            localFile: { publicURL: mainLogo },
            alternativeText: alt2,
          },
          logos,
        },
      },
    },
  } = useStaticQuery(query);

  const image1 = getImage(sharpImage1);

  return (
    <StyledFeaturedCards>
      <div className="card card--1" style={{ height: cardHeight }}>
        <h2 className="card-title">{title}</h2>
        <p className="card-text">{text}</p>
        <Button>
          <a href={link}>{cta}</a>
        </Button>
        <GatsbyImage image={image1} alt={alt1} className="card-image" />
      </div>
      <div className="card card--2" ref={cardTwoRef}>
        <img src={mainLogo} alt={alt2} className="card-2-logo" />
        <p className="card-2-text">{text2}</p>
        <div className="card-logos-container">
          {logos.map((icon, i) => {
            const image = getImage(icon.localFile);
            return <GatsbyImage image={image} alt={icon.alternativeText} key={i} className="card-2-featured-logo" />;
          })}
        </div>
      </div>
    </StyledFeaturedCards>
  );
};

const query = graphql`
  query FeaturedCards {
    strapiNicoleWebsite {
      homePage {
        card1 {
          cta
          link
          text
          title
          image {
            alternativeText
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED)
              }
            }
          }
        }
        card2 {
          text
          mainLogo {
            alternativeText
            localFile {
              publicURL
            }
          }
          logos {
            alternativeText
            localFile {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED, placeholder: TRACED_SVG)
              }
            }
          }
        }
      }
    }
  }
`;

export default FeaturedCards;
