import React, { useContext } from "react";

import AppContext from "../context/AppContext";

import Seo from "../components/Seo";
import Hero from "../sections/home/Hero";
import FeaturedCards from "../sections/home/FeaturedCards";
import BuildingBrands from "../sections/home/BuildingBrands";
import HomeYouTubeVideo from "../sections/home/HomeYouTubeVideo";
import NewsletterSubscriber from "../sections/home/NewsletterSubscriber";
import KeepingTabsCard from "../sections/home/KeepingTabsCard";
import YTPlaylist from "../components/YTPlaylist";
import HomeBusinessCards from "../sections/home/HomeBusinessCards";
import HomeBottomCards from "../sections/home/HomeBottomCards";
import Layout from "../layout/Layout";

const IndexPage = () => {
  // eslint-disable-next-line
  const appData = useContext(AppContext);

  return (
    <main>
      <Seo title={"Nicole Tabs"} description={""} language="en" />
      <Layout>
        <Hero />
        <FeaturedCards />
        <BuildingBrands />
        <HomeYouTubeVideo />
        <NewsletterSubscriber />
        <KeepingTabsCard />
        <YTPlaylist />
        <HomeBusinessCards />
        <HomeBottomCards />
      </Layout>
    </main>
  );
};

export default IndexPage;
