import React from "react";
import styled from "styled-components";

const StyledAlternativeButton = styled.button`
  background-color: transparent;
  border: none;
  transition: all 0.3s ease-in-out;

  .button--hovered {
    color: var(--color-primary);
  }

  .button--hovered > * {
    color: var(--color-primary);
  }

  a {
    background-color: transparent;
    transition: all 0.3s ease-in-out;

    &:hover {
      color: var(--color-primary);
    }
  }

  .arrow {
    transition: all 0.3s ease-in-out;
    display: inline-block;

    &--hovered {
      transform: translateX(10px);
      color: var(--color-primary);
    }
  }
`;

const AlternativeButton = ({ children }) => {
  const arrowRef = React.useRef(null);
  const buttonRef = React.useRef(null);

  function handleHover() {
    arrowRef.current.classList.add("arrow--hovered");
    buttonRef.current.classList.add("button--hovered");
  }
  function handleMouseLeave() {
    arrowRef.current.classList.remove("arrow--hovered");
    buttonRef.current.classList.remove("button--hovered");
  }

  return (
    <StyledAlternativeButton onMouseEnter={handleHover} onMouseLeave={handleMouseLeave} ref={buttonRef}>
      {children}{" "}
      <span className="arrow" ref={arrowRef}>
        &gt;
      </span>
    </StyledAlternativeButton>
  );
};

export default AlternativeButton;
