import React from "react";
import styled from "styled-components";

const StyledYouTubeVideo = styled.div`
  .iframe-container {
    padding-bottom: 56.25%;
    position: relative;
    margin-bottom: 6rem;
  }

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

const YouTubeVideo = () => {
  return (
    <StyledYouTubeVideo className="container">
      <div className="iframe-container">
        <iframe
          src="https://www.youtube.com/embed/TYkXFSTi1N8"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>
    </StyledYouTubeVideo>
  );
};

export default YouTubeVideo;
