import React, { useState, useRef } from "react";
import styled, { css } from "styled-components";
import respond from "../styles/abstracts/mediaqueries";

import ReCAPTCHA from "react-google-recaptcha";
import { handleSubscriptionFormSubmit } from "../utils/handleFormSubmits";

import Button from "../components/Button";

const Wrapper = styled.form`
  display: grid;
  grid-template-columns: repeat(20, 1fr);
  grid-column-gap: 2rem;
  justify-content: center;
  align-content: center;
  align-items: center;
  justify-items: center;
  width: 100%;
  font-size: 1.8rem;
  color: var(--white);

  ${respond(
    "tab-land",
    css`
      grid-template-rows: repeat(2, 1fr);
      grid-template-columns: repeat(21, 1fr);
      row-gap: 2rem;
    `
  )}
  ${respond(
    "phone-port",
    css`
      grid-template-rows: 8rem max-content max-content max-content;
      grid-template-columns: 3rem 1fr;
      row-gap: 1.5rem;
      column-gap: 1.5rem;
    `
  )}
  .input,
  .button {
    height: 5rem;
  }
  .input {
    padding-left: 1rem;
    width: 100%;
    grid-row: 1 / 2;
    background-color: rgba(253, 253, 253, 0.25);
    border: 1px solid #ffd6e9;
    border-radius: 5px;
    color: #ffd6e9;

    &:focus {
      outline: none;
    }

    &::placeholder {
      color: #ffd6e9;
    }

    ${respond(
      "tab-land",
      css`
        grid-column: 6 / 9;
      `
    )}
    ${respond(
      "phone-port",
      css`
        grid-column: 1 / -1;
      `
    )}

    &--name {
      grid-column: 1 / 6;

      ${respond(
        "tab-land",
        css`
          grid-column: 6 / 10;
        `
      )}
      ${respond(
        "tab-port",
        css`
          grid-column: 2 / 9;
        `
      )}
      ${respond(
        "phone-port",
        css`
          grid-column: 1 / 3;
        `
      )}
    }
    &--email {
      grid-column: 6 / 11;

      ${respond(
        "tab-land",
        css`
          grid-column: 10 / 14;
        `
      )}
      ${respond(
        "tab-port",
        css`
          grid-column: 9 / 16;
        `
      )}
      ${respond(
        "phone-port",
        css`
          grid-column: 1 / 3;
          grid-row: 2 / 3;
        `
      )}
    }
  }
  .button {
    background-color: var(--white);
    color: var(--black);
    border: none;
    cursor: pointer;
    grid-column: 11/14;
    grid-row: 1/2;
    width: 100%;
    text-transform: uppercase;
    padding: 0;
    letter-spacing: 0;

    ${respond(
      "tab-land",
      css`
        grid-column: 14 / 17 !important;
      `
    )}
    ${respond(
      "tab-port",
      css`
        grid-column: 16 / 21 !important;
      `
    )}
    ${respond(
      "phone-port",
      css`
        grid-row: 4 / 5 !important;
        grid-column: 1 / 3 !important;
      `
    )}

    &:hover {
      background-color: #d8d8d8;
    }

    ${respond(
      "tab-land",
      css`
        grid-column: 9 / 11;
      `
    )}
    ${respond(
      "phone-port",
      css`
        grid-column: 10 / 15;
      `
    )}
  }
  .checkbox {
    transform: scale(2);
    grid-column: 14 / 15;
    grid-row: 1 / 2;
    ${respond(
      "tab-land",
      css`
        grid-row: 2 / 3;
        grid-column: 6 / 7;
      `
    )}
    ${respond(
      "tab-port",
      css`
        grid-column: 4 / 5;
      `
    )}
    ${respond(
      "phone-port",
      css`
        grid-row: 3 / 4;
        grid-column: 1 / 2;
      `
    )}
  }
  .label {
    font-size: 1.4rem;
    grid-column: 15 / 21;
    grid-row: 1 / 2;
    line-height: 1.3;
    ${respond(
      "tab-land",
      css`
        grid-row: 2 / 3;
        grid-column: 7 / 16;
      `
    )}
    ${respond(
      "tab-port",
      css`
        grid-column: 5 / 19;
      `
    )}
    ${respond(
      "phone-port",
      css`
        grid-row: 3 / 4;
        grid-column: 2 / 3;
      `
    )}
  }
  a {
    font-weight: 700;
    color: var(--white);
    text-decoration: underline;
  }
`;

const SubscriptionForm = ({ success, setSuccess, cta, privacy, namePlaceholder }) => {
  const [formData, setFormData] = useState({ email: "", name: "", privacy: false });
  const recaptchaRef = useRef();

  function handleChange(e) {
    const value = e.target.type === "checkbox" ? e.target.checked : e.target.value;
    setFormData({ ...formData, [e.target.name]: value });
  }

  return (
    <Wrapper onSubmit={(e) => handleSubscriptionFormSubmit(e, formData, setSuccess, recaptchaRef)}>
      <ReCAPTCHA
        ref={recaptchaRef}
        style={{ zIndex: 600 }}
        size="invisible"
        sitekey={process.env.GATSBY_RECAPTCHA_SITE_KEY}
      />
      <input
        type="text"
        name="name"
        className="input input--name"
        id="subscriber-name"
        placeholder={namePlaceholder}
        onChange={handleChange}
        value={formData.name}
      />
      <input
        type="email"
        name="email"
        className="input input--email"
        id="subscriber-email"
        placeholder="Email"
        onChange={handleChange}
        value={formData.email}
      />
      <input
        type="checkbox"
        className="checkbox"
        name="privacy"
        id="privacy"
        onChange={handleChange}
        value={formData.privacy}
        checked={formData.privacy}
      />
      {/* eslint-disable-next-line */}
      <label
        type="checkbox"
        name="privacy"
        htmlFor="privacy"
        className="label"
        dangerouslySetInnerHTML={{ __html: privacy }}
      />

      <Button type="submit" className="button">
        {cta}{" "}
      </Button>
    </Wrapper>
  );
};

export default SubscriptionForm;
