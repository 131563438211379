import React from "react";
import styled, { css } from "styled-components";
import { graphql, useStaticQuery } from "gatsby";
import respond from "../../styles/abstracts/mediaqueries";

import AlternativeButton from "../../components/AlternativeButton";

const StyledBuildingBrands = styled.section`
  .container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 2rem;
    justify-items: center;
    align-items: center;

    ${respond(
      "tab-port",
      css`
        grid-template-columns: 1fr;
        gap: 4rem;
      `
    )}

    h3 {
      font-family: var(--body-font);
      font-weight: 100;
      text-transform: uppercase;
    }
    .left-container,
    .right-container {
      display: flex;
      flex-direction: column;
      gap: 3rem;
    }

    .left-container {
      align-items: center;
    }

    .right-container {
      align-items: flex-start;

      ${respond(
        "tab-port",
        css`
          text-align: center;
          display: flex;
          flex-direction: column;
          align-items: center;
        `
      )}
    }

    .logo {
      max-width: 90%;
    }
  }
`;

const BuildingBrands = () => {
  const {
    strapiNicoleWebsite: {
      homePage: {
        buildingBrands: {
          title,
          description,
          cta,
          link,
          logo: {
            alternativeText,
            localFile: { publicURL },
          },
        },
      },
    },
  } = useStaticQuery(query);

  return (
    <StyledBuildingBrands>
      <div className="container">
        <div className="left-container">
          <h3>{title}</h3>
          <img src={publicURL} alt={alternativeText} className="logo" />
        </div>
        <div className="right-container">
          <p>{description}</p>
          <AlternativeButton>
            <a href={link}>{cta}</a>
          </AlternativeButton>
        </div>
      </div>
    </StyledBuildingBrands>
  );
};

export const query = graphql`
  query BuildingBrands {
    strapiNicoleWebsite {
      homePage {
        buildingBrands {
          cta
          description
          link
          title
          logo {
            localFile {
              publicURL
            }
            alternativeText
          }
        }
      }
    }
  }
`;

export default BuildingBrands;
