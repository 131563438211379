import React, { useState, useContext } from "react";
import styled, { css } from "styled-components";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import { MdTouchApp } from "react-icons/md";
import AppContext from "../context/AppContext";
import respond from "../styles/abstracts/mediaqueries";

import Button from "./Button";

const StyledHomeBottomCard = styled.article`
  position: relative;
  width: 100%;
  padding: 7%;
  color: var(--white);
  height: 45vh;

  ${respond(
    "phone-land",
    css`
      height: 120vh;
    `
  )}

  .card {
    &__front {
      transition: all 0.3s ease-in-out;
      opacity: ${({ hovered }) => (!hovered ? "0" : "1")};
      display: flex;
      justify-content: space-between;

      flex-direction: column;
      align-items: center;
      height: 100%;

      &__bottom {
        display: flex;
        align-items: center;
        gap: 2rem;
        flex-direction: column;
      }
    }

    &__title {
      text-transform: uppercase;
      font-family: var(--body-font);
      color: var(--white);
      letter-spacing: 7px;
      font-weight: 200;
    }

    &__description {
      text-align: center;
      width: 70%;
      margin: 0 auto;
      line-height: 1.5;
    }

    &__image {
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      width: 100%;
      height: 100%;

      &::after {
        transition: all 1s ease-in-out;
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 500;
        background-color: var(--white);
        opacity: ${({ hovered }) => (hovered ? 0 : 0.4)};
      }

      &__img {
        transition: all 0.3s ease-in-out;
        filter: ${({ hovered }) => (!hovered ? "sepia(.8)" : "initial")};
      }
    }
  }

  .back-title {
    width: 40%;
    transition: all 0.2s ease-in-out;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: ${({ hovered }) => (!hovered ? "1 !important" : "0 !important")};
  }

  .touch-icon {
    color: gray;
    position: absolute;
    top: 5%;
    left: 5%;
    width: 7%;
    height: 7%;
  }
`;

const HomeBottomCard = ({
  backTitle,
  bgImage: { alternativeText: bgAlt, localFile: bgRawImage },
  cta,
  description,
  link,
  title,
}) => {
  const { isTabLand } = useContext(AppContext);
  const [hovered, setHovered] = useState(false);
  const backgroundImage = getImage(bgRawImage);
  const backTitleImage = getImage(backTitle.localFile);

  function handleMouseEnter() {
    setHovered(true);
  }
  function handleMouseLeave() {
    setHovered(false);
  }
  return (
    <StyledHomeBottomCard
      className="card"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      hovered={hovered}
    >
      <div className="card__front">
        <h4 className="card__title">{title}</h4>
        <div className="card__front__bottom">
          <p className="card__description">{description}</p>
          <Button>
            <Link to={link}>{cta}</Link>
          </Button>
        </div>
      </div>

      <GatsbyImage
        className="card__image"
        objectFit="cover"
        objectPosition="center"
        image={backgroundImage}
        alt={bgAlt}
        imgClassName="card__image__img"
      />
      <GatsbyImage className="back-title" image={backTitleImage} alt={backTitle.alternativeText} />
      {isTabLand && <MdTouchApp className="touch-icon" />}
    </StyledHomeBottomCard>
  );
};

export default HomeBottomCard;
