import React, { useContext } from "react";
import styled, { css } from "styled-components";
import { graphql, useStaticQuery } from "gatsby";
import respond from "../../styles/abstracts/mediaqueries";

import SubscriptionForm from "../../components/SubscriptionForm";
import AppContext from "../../context/AppContext";

const Wrapper = styled.section`
  background-color: var(--color-primary);
  height: max-content;
  padding: 4rem 0;
  position: relative;

  .container-fluid {
    display: grid;
    grid-template-columns: repeat(14, 1fr);

    align-items: center;
    justify-items: center;
    align-content: center;
    justify-content: center;
    ${respond(
      "laptop",
      css`
        padding: 0 2rem;
      `
    )}
    ${respond(
      "tab-land",
      css`
        display: flex;
        flex-direction: column;
        gap: 2rem;
      `
    )}
  }

  .title {
    grid-column: 2 / 5;
    font-size: 1.6rem;
    font-weight: 200;
    color: var(--white);
    font-family: var(--body-font);

    ${respond(
      "laptop",
      css`
        grid-column: 1 / 4;
      `
    )}
    ${respond(
      "phone-port",
      css`
        text-align: center;
      `
    )}
  }

  .form-container {
    grid-column: 5 / 14;
    width: 100%;
    ${respond(
      "laptop",
      css`
        grid-column: 4 / 15;
      `
    )}
    ${respond(
      "phone-port",
      css`
        width: 95%;
      `
    )}
  }
`;

const NewsletterSubscriber = () => {
  const {
    strapiNicoleWebsite: {
      homePage: {
        subscriptionForm: { cta, emailPlaceholder, intro, namePlaceholder, privacyPolicy },
      },
    },
  } = useStaticQuery(query);

  const { alertState, setAlertState } = useContext(AppContext);

  return (
    <Wrapper className="newsletter-block">
      <div className="container-fluid">
        <h2 className="title">{intro}</h2>
        <div className="form-container">
          <SubscriptionForm
            success={alertState.success}
            setSuccess={setAlertState}
            emailPlaceholder={emailPlaceholder}
            namePlaceholder={namePlaceholder}
            cta={cta}
            privacy={privacyPolicy}
          />
        </div>
      </div>
    </Wrapper>
  );
};

export const query = graphql`
  query SubscriptionForm {
    strapiNicoleWebsite {
      homePage {
        subscriptionForm {
          cta
          emailPlaceholder
          intro
          namePlaceholder
          privacyPolicy
        }
      }
    }
  }
`;

export default NewsletterSubscriber;
