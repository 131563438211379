import React, { useState } from "react";
import styled, { css } from "styled-components";
import { useStaticQuery, graphql } from "gatsby";
import { getImage, GatsbyImage } from "gatsby-plugin-image";
import respond from "../../styles/abstracts/mediaqueries";

import heroVideo from "../../videos/hero-video.mp4";

import Button from "../../components/Button";
import HeroBottomHeader from "../../components/HeroBottomHeader";

const Header = styled.header`
  height: 100vh;
  position: relative;

  ${respond(
    "phone-land",
    css`
      height: 200vh;
    `
  )}

  .background {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;

    img {
      filter: brightness(0.8);
    }
  }

  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    position: relative;
  }

  .container.icons-box {
    justify-content: space-between;
  }

  .nicole-svg {
    width: 100%;
    transform: translate(-45px, -45px);

    ${respond(
      "laptop",
      css`
        width: 80%;
      `
    )}
    ${respond(
      "tab-land",
      css`
        width: 70%;
      `
    )}
    ${respond(
      "tab-port",
      css`
        width: 100%;
        transform: translate(-35px, -15rem);
      `
    )}
    ${respond(
      "phone-port",
      css`
        transform: translate(0, -21rem);
        width: 100%;
      `
    )}
  }

  .nicole-image {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 26%;

    ${respond(
      "tab-port",
      css`
        width: 45%;
      `
    )}
    ${respond(
      "phone-port",
      css`
        width: 65%;
        transform: translate(-50%, -60%);
      `
    )}
  }

  .text-container {
    position: absolute;
    bottom: 20%;
    left: 0;
    color: var(--white);
    width: 30%;

    ${respond(
      "tab-port",
      css`
        bottom: 30%;
        width: 75%;
        text-align: center;
        background-color: rgba(204, 177, 110, 0.8);
        padding: 2rem;
        margin-left: 50%;
        transform: translateX(-50%);
        border-radius: 10px;
        animation-name: fadeInUpBig;
        animation-duration: 1.5s;
      `
    )}
    ${respond(
      "phone-port",
      css`
        bottom: 35%;
        width: 100%;
        padding: 1.5rem;
        border-radius: 0;
        font-size: 1.6rem;
      `
    )}
  }

  h1 {
    text-transform: capitalize;
    color: var(--white);
    margin: 0;
    font-weight: 300;
    font-size: 4rem;

    ${respond(
      "laptop",
      css`
        font-size: 2.6rem;
      `
    )}
    ${respond(
      "tab-land",
      css`
        font-size: 2.6rem;
      `
    )}
    ${respond(
      "tab-port",
      css`
        font-weight: 700;
      `
    )}
    ${respond(
      "tab-port",
      css`
        font-size: 2rem;
      `
    )}
  }

  p {
    margin: 2rem 0 3rem 0;
    line-height: 1.5;
    font-weight: 300;

    ${respond(
      "laptop",
      css`
        font-size: 1.5rem;
      `
    )}
    ${respond(
      "tab-land",
      css`
        font-size: 1.6rem;
      `
    )}
    ${respond(
      "tab-port",
      css`
        width: 100%;
        margin: 2rem auto 3rem auto;
      `
    )}
    ${respond(
      "phone-port",
      css`
        font-size: 1.4rem;
        margin: 2rem auto;
      `
    )}
  }

  .video-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 90vh;

    ${respond(
      "tab-land",
      css`
        height: 84vh;
      `
    )}
    ${respond(
      "tab-port",
      css`
        height: 73vh;
      `
    )}
    ${respond(
      "phone-land",
      css`
        height: 146vh;
      `
    )}
    ${respond(
      "phone-port",
      css`
        height: 65vh;
      `
    )}
  }

  .video {
    position: absolute;
    height: 90vh;
    top: 0;
    left: 0;
    width: 100vw;
    object-fit: cover;
    object-position: top;

    ${respond(
      "tab-land",
      css`
        height: 84vh;
      `
    )}
    ${respond(
      "tab-port",
      css`
        height: 73vh;
      `
    )}
    ${respond(
      "tab-port",
      css`
        height: 73vh;
      `
    )}
    ${respond(
      "phone-land",
      css`
        height: 146vh;
      `
    )}
    ${respond(
      "phone-port",
      css`
        height: 65vh;
      `
    )}
  }

  .close-video {
    z-index: 200;
    position: absolute;
    bottom: 1.5rem;
    left: 15%;
    transform: translate(-50%, -50%);

    ${respond(
      "tab-land",
      css`
        bottom: 4rem;
      `
    )}
    ${respond(
      "tab-port",
      css`
        bottom: 5rem;
        left: 20%;
      `
    )}
    ${respond(
      "phone-port",
      css`
        bottom: 5rem;
        left: 35%;
      `
    )}
  }
`;

const Hero = () => {
  const [playing, setPlaying] = useState(false);
  const {
    strapiNicoleWebsite: {
      homePage: {
        hero: {
          title,
          cta,
          description,
          logo: {
            alternativeText: logoAlt,
            localFile: { publicURL: nicoleLogo },
          },
          bgPicture,
          nicolePicture,
        },
      },
    },
  } = useStaticQuery(query);

  const nicoleImage = getImage(nicolePicture.localFile);
  const bgImage = getImage(bgPicture.localFile);

  return (
    <Header>
      <div className={playing ? "" : "container"}>
        {playing ? (
          <div className="video-container">
            {/* eslint-disable-next-line */}
            <video controls className="video">
              <source src={heroVideo} type="video/mp4" />
            </video>
            <Button className="close-video" onClick={() => setPlaying(false)}>
              Close video
            </Button>
          </div>
        ) : (
          <>
            <img src={nicoleLogo} alt={logoAlt} className="nicole-svg" />
            <GatsbyImage image={nicoleImage} alt={nicolePicture.alternativeText} className="nicole-image" />
            <div className="text-container">
              <h1>{title}</h1>
              <p>{description}</p>
              <Button onClick={() => setPlaying(true)}>{cta}</Button>
            </div>
          </>
        )}
      </div>
      {!playing && <GatsbyImage image={bgImage} className="background" alt={bgPicture.alternativeText} />}
      <HeroBottomHeader />
    </Header>
  );
};

const query = graphql`
  {
    strapiNicoleWebsite {
      homePage {
        hero {
          title
          cta
          description
          logo {
            alternativeText
            localFile {
              publicURL
            }
          }
          bgPicture {
            alternativeText
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
              }
            }
          }
          nicolePicture {
            alternativeText
            localFile {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
              }
            }
          }
        }
      }
    }
  }
`;

export default Hero;
