import React from "react";
import styled, { css } from "styled-components";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage, withArtDirection } from "gatsby-plugin-image";
import AlternativeButton from "../../components/AlternativeButton";
import respond from "../../styles/abstracts/mediaqueries";

const StyledHomeBusinessCards = styled.section`
  .company-card {
    //background-color: rosybrown;
    position: relative;
    height: 100vh;
    display: flex;
    justify-content: center;

    ${respond(
      "phone-land",
      css`
        height: 150vh !important;
      `
    )}

    &:not(:last-child) {
      margin-bottom: 6rem;
    }

    &--left {
      justify-content: flex-start;
      align-items: center;
    }
    &--bottom {
      justify-content: center;
      align-items: flex-end;
    }
    &--right {
      justify-content: flex-end;
      align-items: center;
    }
  }

  .card-content {
    position: absolute;
    padding: 7%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    width: 50%;
    text-align: center;

    ${respond(
      "phone-port",
      css`
        width: 100%;
        top: 5rem;
      `
    )}

    .logo {
      width: 30rem;

      ${respond(
        "phone-port",
        css`
          width: 80%;
        `
      )}
    }

    &--white {
      color: var(--white);

      button {
        color: var(--white);

        a {
          color: var(--white);

          &:hover {
            color: var(--color-primary);
          }
        }
      }
    }
  }

  .card-bg {
    z-index: -1;
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  .btn-a {
    text-transform: uppercase;
  }
`;

const HomeBusinessCards = () => {
  const {
    strapiNicoleWebsite: {
      homePage: { homeCompanyCard: homeCompanyCards },
    },
  } = useStaticQuery(query);

  return (
    <StyledHomeBusinessCards>
      {homeCompanyCards.map((card) => {
        const { alignment, bgImage, companyLogo, cta, id, link, text, black, heightPercentage, tabPortImage } = card;
        const logoImage = getImage(companyLogo.localFile) || companyLogo.localFile.publicURL;
        const isSvg = getImage(companyLogo.localFile) == null;
        const images = withArtDirection(getImage(bgImage.localFile), [
          {
            media: "(max-width: 56.25em)",
            image: getImage(tabPortImage.localFile),
          },
        ]);

        return (
          <article
            key={id}
            className={`company-card company-card--${alignment}`}
            style={{ height: `${heightPercentage}vh` }}
          >
            <div className={black ? "card-content card-content--white" : "card-content"}>
              {isSvg ? (
                <img src={logoImage} alt={companyLogo.alternativeText} className="logo" />
              ) : (
                <GatsbyImage image={logoImage} alt={companyLogo.alternativeText} className="logo" />
              )}
              <p>{text}</p>
              <AlternativeButton>
                <a href={link} className="btn-a">
                  {cta}
                </a>
              </AlternativeButton>
            </div>
            <GatsbyImage image={images} alt={bgImage.alternativeText} className="card-bg" />
          </article>
        );
      })}
    </StyledHomeBusinessCards>
  );
};

const query = graphql`
  {
    strapiNicoleWebsite {
      homePage {
        homeCompanyCard {
          alignment
          cta
          id
          link
          text
          black
          heightPercentage
          bgImage {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED, placeholder: TRACED_SVG)
              }
            }
            alternativeText
          }
          companyLogo {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
              publicURL
            }
            alternativeText
          }
          tabPortImage {
            alternativeText
            localFile {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED, placeholder: TRACED_SVG)
              }
            }
          }
        }
      }
    }
  }
`;

export default HomeBusinessCards;
