import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import axios from "axios";
import respond from "../styles/abstracts/mediaqueries";

import { FaYoutube } from "react-icons/fa";

const StyledYTPlaylist = styled.section`
  height: auto;
  .grid-container {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    height: 100%;
    align-items: flex-start;
    column-gap: 6rem;

    ${respond(
      "tab-port",
      css`
        grid-template-columns: 1fr;
        grid-template-rows: repeat(2, max-content);
      `
    )}
  }

  .left-container {
    position: relative;
    padding-bottom: 56.25%; /* 16:9, for an aspect ratio of 1:1 change to this value to 100% */
    //width: 100%;
    grid-column: 1 / 6;

    ${respond(
      "tab-port",
      css`
        grid-column: 1 / -1;
        grid-row: 1 / 2;
      `
    )}
  }

  .right-container {
    margin-top: 3rem;
    grid-column: 6 / 9;
    ${respond(
      "tab-port",
      css`
        grid-column: 1 / -1;
        grid-row: 2 / 3;
      `
    )}
  }

  .iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .title {
    margin: 1rem 0;
  }

  .episode-number {
    font-size: 2.3rem;
  }

  .button {
    color: var(--white);
    background-color: #bb0b0b;
    padding: 1rem 2rem;
    display: inline-block;
    border-radius: 5px;
    margin-top: 1rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;
    width: max-content;
    transition: 0.2s ease-in-out;

    &:hover {
      background-color: #8b0909;
    }
  }

  .yt-logo {
    height: 100%;
  }

  .paragraph {
    margin-bottom: 2rem;
  }
`;

const YT_PLAYLIST_ITEMS_API = "https://www.googleapis.com/youtube/v3/playlistItems";
const KEEPING_TABS_PLAYLIST = "PLR-KOvs_zutDS2yEom6txqFOB5c2rIwp9";

const YTPlaylist = () => {
  const [videoData, setVideoData] = useState({});
  useEffect(() => {
    (async () => {
      const {
        data: { items },
      } = await axios.get(YT_PLAYLIST_ITEMS_API, {
        params: {
          part: "snippet",
          playlistId: KEEPING_TABS_PLAYLIST,
          order: "date",
          key: process.env.GATSBY_YT_API_KEY,
        },
      });
      let index = 1;
      let rightVideo = {};

      while (items[items.length - index].snippet.title === "Private video" && index <= items.length) {
        index++;
      }
      if (index <= items.length) {
        rightVideo = items[items.length - index];
      } else {
        rightVideo = items[items.length - 1];
      }

      setVideoData(rightVideo);
    })();
  }, []);

  const { snippet = {} } = videoData;
  const { description = "" } = snippet;
  const descriptionParagraphs = description.split("\n\n");

  return (
    <StyledYTPlaylist>
      <div className="container grid-container">
        <div className="left-container">
          <iframe
            src={`https://www.youtube.com/embed/${snippet?.resourceId?.videoId}`}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            className="iframe"
          ></iframe>
        </div>

        <div className="right-container">
          <p className="episode-number">EPISODE {snippet?.position}</p>
          <h3 className="title">{snippet?.title}</h3>
          <div>
            <p className="paragraph">{descriptionParagraphs[0]}</p>
          </div>
          <a className="button" href={`https://www.youtube.com/watch?v=${snippet?.resourceId?.videoId}`}>
            <FaYoutube className="yt-logo" />
            Watch on YT
          </a>
        </div>
      </div>
    </StyledYTPlaylist>
  );
};

export default YTPlaylist;
