import React from "react";
import styled, { css } from "styled-components";
import { graphql, useStaticQuery } from "gatsby";
import respond from "../../styles/abstracts/mediaqueries";

import HomeBottomCard from "../../components/HomeBottomCard";

const StyledBottomCards = styled.section`
  ${respond(
    "phone-port",
    css`
      padding-top: 0 !important;
    `
  )}
  .container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 6rem;
    align-content: center;
    justify-items: center;
    justify-content: center;

    ${respond(
      "tab-port",
      css`
        grid-template-columns: 1fr;
      `
    )}
  }
`;

const BottomCards = () => {
  const {
    strapiNicoleWebsite: {
      homePage: { homeBottomCards },
    },
  } = useStaticQuery(query);

  return (
    <StyledBottomCards>
      <div className="container">
        {homeBottomCards.map((card) => {
          return <HomeBottomCard key={card.id} {...card} />;
        })}
      </div>
    </StyledBottomCards>
  );
};

export const query = graphql`
  query HomeBottomCards {
    strapiNicoleWebsite {
      homePage {
        homeBottomCards {
          cta
          description
          id
          link
          title
          bgImage {
            alternativeText
            localFile {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED, placeholder: TRACED_SVG)
              }
            }
          }
          backTitle {
            alternativeText
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: TRACED_SVG, layout: CONSTRAINED)
              }
            }
          }
        }
      }
    }
  }
`;

export default BottomCards;
